



































































































































































































































































































.processor-select-input {
  @apply bg-white border border-blue-200 duration-300 font-medium px-6 py-3 relative rounded-sm text-gray-600 {}
  font-size: 13px;
  &:hover {
    @apply bg-blue-200 border-blue-200 text-blue-900 {} }
  &:focus-within {
    @apply border-blue-500 text-blue-500 {} } }
.processor-filter-title {
  @apply font-bold mb-2 mr-2 text-sm uppercase w-full {} }
.processor-filter-item {
  @apply bg-white border cursor-pointer font-bold mb-2 mr-2 px-2 py-2 rounded-sm text-xs text-gray-600 tracking-wide uppercase {}
  &:hover {
    @apply bg-blue-100 border-blue-500 text-blue-500 {} }
  &.active {
    @apply bg-blue-500 border-blue-500 text-white {} } }
